@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;600;700;800;900&family=Noto+Serif+TC:wght@400;500;600;700;800&display=swap');


body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
  html{
    @apply scroll-smooth;
    font-family: "Quicksand", 'Noto Sans TC',  sans-serif;
  }
  body{
    @apply bg-white
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    display: none;
    /*z-index: -10;*/
  }
  h1{@apply text-6xl font-bold}
  h2{@apply text-5xl font-bold}
  h3{@apply text-4xl font-bold}
  h4{@apply text-3xl font-bold}
  h5{@apply text-2xl font-bold}
  h6{@apply text-xl font-bold}
  .ReactMarkdownNoP p{
    margin: 0;
    padding: 0
  }
  .ReactMarkdownAbout p{
    margin: 24px auto
  }
  .ReactMarkdown p{
    margin: 36px auto
  }
  .ReactMarkdown strong{
    color: #81859e
  }
  .ReactMarkdown code{
    text-align: left;
    display: inline;
    word-break: break-all;
  }
  .handMask{
    /*-webkit-mask-image: url('../public/images/catnew-07.png'); //舊版貓手*/
    -webkit-mask-image: url('../public/images/cat_hand.png');
    -webkit-mask-size: 28vh;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: 50% 60%;
  }
  @media (max-height:700px) {
    .handMask{
      -webkit-mask-position: 50% 100%;
    }
  }
  @media (max-height:570px) {
    .handMask{
      -webkit-mask-position: 50% 140%;
    }
  }
  .scroll_attention:after {
    opacity: 1;
    -webkit-animation-name: scrollAttentionAfter;
    animation-name: scrollAttentionAfter;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }

  @-webkit-keyframes scrollAttentionAfter {
    0% {
      -webkit-clip-path: inset(0 0 100% 0);
      clip-path: inset(0 0 100% 0);
      bottom: 5%
    }

    40%,60% {
      -webkit-clip-path: inset(0 0 0% 0);
      clip-path: inset(0 0 0% 0);
      bottom: 0
    }

    100% {
      -webkit-clip-path: inset(100% 0 0% 0);
      clip-path: inset(100% 0 0% 0);
      bottom: -5%
    }
  }

  @keyframes scrollAttentionAfter {
    0% {
      -webkit-clip-path: inset(0 0 100% 0);
      clip-path: inset(0 0 100% 0);
      bottom: 5%
    }

    40%,60% {
      -webkit-clip-path: inset(0 0 0% 0);
      clip-path: inset(0 0 0% 0);
      bottom: 0
    }

    100% {
      -webkit-clip-path: inset(100% 0 0% 0);
      clip-path: inset(100% 0 0% 0);
      bottom: -5%
    }
  }
}